import { getUnits } from "~/helpers/property-helpers";

export const getUsageBreakdownByArea = function (property, maxUsageTypes, remainingUsageTypesText) {
  const units = getUnits(property);

  if (!units || units.length === 0) {
    return {};
  }

  const usageBreakdown = {};
  const totalArea = units.reduce((a, b) => {
    return a + b.totalArea;
  }, 0);

  units.forEach((unit) => {
    if (!unit.usageCode) {
      return;
    }

    if (!usageBreakdown[unit.usageCode]) {
      usageBreakdown[unit.usageCode] = {
        usageCode: unit.usageCode,
        usageText: unit.usageText,
        usageArea: 0,
        usagePercent: 0,
      };
    }

    usageBreakdown[unit.usageCode].usageArea += unit.totalArea;
    usageBreakdown[unit.usageCode].usagePercent = (usageBreakdown[unit.usageCode].usageArea / totalArea) * 100;
  });

  if (maxUsageTypes && Object.keys(usageBreakdown).length > maxUsageTypes) {
    const sortedUsageBreakdown = Object.values(usageBreakdown).sort((a, b) => {
      return b.usageArea - a.usageArea;
    });

    const topUsageBreakdown = sortedUsageBreakdown.slice(0, maxUsageTypes - 1);
    const remainingUsageBreakdown = sortedUsageBreakdown.slice(maxUsageTypes - 1);

    const remainingUsageArea = remainingUsageBreakdown.reduce((a, b) => {
      return a + b.usageArea;
    }, 0);

    const remainingUsagePercent = (remainingUsageArea / totalArea) * 100;

    const remainingUsageBreakdownItem = {
      usageCode: "OTHER",
      usageText: remainingUsageTypesText,
      usageArea: remainingUsageArea,
      usagePercent: remainingUsagePercent,
    };

    return topUsageBreakdown.concat(remainingUsageBreakdownItem);
  }

  return usageBreakdown;
};
